import React from 'react'
import classNames from 'classnames'

import { EloTooltip } from '@elo-ui/components/elo-tooltip'

import './elo-status-badge.scss'

export type Status = 'success' | 'warning' | 'error' | 'blocked' | 'in-review'

interface Props {
  className?: string
  status?: Status
  icon?: React.ReactNode
  label?: string | React.ReactNode
  tooltipContent?: React.ReactNode | string
  tooltipPlacement?: 'bottom' | 'top' | 'right' | 'left'
  children?: React.ReactNode
  highlight?: boolean
  disabled?: boolean
  backgroundColor?: string
  badgeColor?: string
  active?: boolean
  onClick?: () => any
}

export const EloStatusBadge: React.FC<Props> = ({
  className,
  status = 'blocked',
  icon,
  label,
  tooltipContent,
  tooltipPlacement = 'top',
  children = <></>,
  highlight = true,
  disabled = false,
  onClick,
  backgroundColor,
  badgeColor,
  active = true,
}) => {
  const classes = classNames(
    'elo-status-badge',
    `elo-status-badge--${status}`,
    { 'elo-status-badge--active': active },
    { 'elo-status-badge--highlight': highlight },
    { 'elo-status-badge--disabled': disabled },
    { 'elo-status-badge--clickable': onClick },
    className
  )

  return (
    <>
      {tooltipContent ? (
        <EloTooltip content={tooltipContent} placement={tooltipPlacement}>
          <div className={classes} onClick={onClick}>
            {icon}
            {label}
            {children}
          </div>
        </EloTooltip>
      ) : (
        <div
          className={classes}
          style={
            {
              '--elo-status-badge-background-color': backgroundColor,
              '--elo-status-badge-color': badgeColor,
            } as React.CSSProperties
          }
          onClick={onClick}
        >
          {icon}
          {label}
          {children}
        </div>
      )}
    </>
  )
}
